import {APIError} from '@bit/stefdegoey.azurefunctions-typeorm-base.request-resolver';
import {User} from '../models/user.model';
import {API_BASE} from '../values';
import {StorageController, SettingKey} from './storage.controller';
import moment from 'moment';

export class UserController {
  static shared = new UserController();

  async getCredentials(): Promise<JWTModel | null> {
    const user = await StorageController.shared.getType<User>(SettingKey.USER);

    if (!user) {
      return null;
    }

    const expiresAt = await StorageController.shared.getType<Date>(SettingKey.ACCESS_TOKEN_EXPIRES_AT);

    if (!expiresAt || moment().utc().isAfter(expiresAt)) {
      await this.refreshCredentials();
      return this.getCredentials();
    }

    return {
      user: (await StorageController.shared.getType<User>(SettingKey.USER)) || new User(),
      accessToken: (await StorageController.shared.getString(SettingKey.ACCESS_TOKEN)) || '',
      refreshToken: (await StorageController.shared.getString(SettingKey.REFRESH_TOKEN)) || '',
      issuedAt: (await StorageController.shared.getType<Date>(SettingKey.ACCESS_TOKEN_ISSUED_AT)) || moment().utc().toDate(),
      expiresAt: (await StorageController.shared.getType<Date>(SettingKey.ACCESS_TOKEN_EXPIRES_AT)) || moment().utc().toDate(),
    };
  }

  async refreshCredentials() {
    const response = await fetch(`${API_BASE}/auth/refresh`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await StorageController.shared.getString(SettingKey.REFRESH_TOKEN)}`,
      },
    });

    if (!response.ok) {
      throw Array.from<APIError>(await response.json());
    }

    const result = (await response.json()) as JWTModel;
    await this.saveCredentials(result);
    return result;
  }

  async register(username: string, password: string): Promise<JWTModel> {
    const response = await fetch(`${API_BASE}/auth/register`, {
      method: 'POST',
      body: JSON.stringify({username, password}),
    });

    if (!response.ok) {
      throw Array.from<APIError>(await response.json());
    }

    const result = (await response.json()) as JWTModel;
    await this.saveCredentials(result);
    return result;
  }

  async saveCredentials(result: JWTModel) {
    await StorageController.shared.setType<User>(SettingKey.USER, result.user);
    await StorageController.shared.setString(SettingKey.ACCESS_TOKEN, result.accessToken);
    await StorageController.shared.setString(SettingKey.REFRESH_TOKEN, result.refreshToken);
    await StorageController.shared.setType<Date>(SettingKey.ACCESS_TOKEN_ISSUED_AT, result.issuedAt);
    await StorageController.shared.setType<Date>(SettingKey.ACCESS_TOKEN_EXPIRES_AT, result.expiresAt);
  }

  async signIn(username: string, password: string): Promise<JWTModel> {
    const response = await fetch(`${API_BASE}/auth/signin`, {
      method: 'POST',
      body: JSON.stringify({username, password}),
    });

    if (!response.ok) {
      throw Array.from<APIError>(await response.json());
    }

    const result = (await response.json()) as JWTModel;
    await this.saveCredentials(result);
    return result;
  }
}

type JWTModel = {
  accessToken: string;
  expiresAt: Date;
  issuedAt: Date;
  refreshToken: string;
  user: User;
};
