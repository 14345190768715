import {Filter} from './filter.model';
import {Webhook} from './webhook.model';

export class User {
  createdAt?: Date;
  filters?: Filter[];
  id?: number;
  password?: string;
  updatedAt?: Date;
  username?: string;
  webhooks?: Webhook[];
}
