import { APIError } from "@bit/stefdegoey.azurefunctions-typeorm-base.request-resolver";
import { Alert } from "../models/alert.model";
import { API_BASE } from "../values";
import { BaseEntityController } from "./base.controller";
import { Filter } from "../models/filter.model";
import { Page } from "../models/page.model";
import moment from "moment";
import { UserController } from "./user.controller";

export interface LatLng {
  latitude: number;
  longitude: number;
}

export class AlertController extends BaseEntityController<Alert> {
  static shared = new AlertController("alerts");

  async getLocation(id: number): Promise<LatLng> {
    const response = await fetch(`${API_BASE}/${this.urlPath}/${id}/location`, {
      method: "GET",
    });

    if (!response.ok) {
      throw Array.from<APIError>(await response.json());
    }

    return (await response.json()) as LatLng;
  }

  async getPage(
    options: { page?: number; query?: string; filters?: Filter[] } = { page: 1 }
  ): Promise<Page<Alert>> {
    if (options.page === 1) {
      this.date = moment().toDate();
    }

    let filters = options.filters
      ?.map((e) => e.id)
      .join(",")
      .toString();

    const response = await fetch(
      `${API_BASE}/${this.urlPath}?page=${
        options.page
      }&query=${encodeURIComponent(options.query || "")}&date=${moment(
        this.date
      ).toISOString()}&filters=${filters}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            (await UserController.shared.getCredentials())?.accessToken
          }`,
        },
      }
    );

    if (!response.ok) {
      throw Array.from<APIError>(await response.json());
    }

    return (await response.json()) as Page<Alert>;
  }
}
