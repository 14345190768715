import React from "react";
import { Alert } from "../../models/alert.model";
import { Page } from "../../models/page.model";
import { Filter } from "../../models/filter.model";
import { setStateAsync } from "../../controllers/util.controller";
import { AlertController } from "../../controllers/alert.controller";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  StorageController,
  SettingKey,
} from "../../controllers/storage.controller";

interface Props {}

interface State {
  alerts: Alert[];
  fetching?: boolean;
  resetting?: boolean;
  lastPage?: Page<Alert>;
  query: string;
  queryDelay?: number;
  refreshing?: boolean;
  activeFilters: Filter[];
}

export class AlertsView extends React.Component<Props, State> {
  readonly state: Readonly<State> = {
    alerts: [],
    activeFilters: [],
    query: "",
  };

  async componentDidMount() {
    await this.reset();
  }

  async fetchNext(): Promise<Page<Alert> | null> {
    if (this.state.fetching) {
      return null;
    }

    await setStateAsync(this, { fetching: true });

    const alerts = this.state.alerts;

    try {
      const page = await AlertController.shared.getPage({
        page: (this.state.lastPage?.currentPage || 0) + 1,
        query: this.state.query,
        filters: this.state.activeFilters,
      });

      if (page.result) {
        alerts.push(...page.result);
      }

      await setStateAsync(this, {
        lastPage: page,
        alerts,
        fetching: page.currentPage === page.amountOfPages,
      });
      return page;
    } catch (error) {
      console.error(error);
      this.setState({ fetching: false });
    }

    return null;
  }

  getAlertsView() {
    const alerts: React.ReactNode[] = [];

    for (let index = 0; index < this.state.alerts.length; index++) {
      const alert = this.state.alerts[index];

      alerts.push(
        <div>
          <h1 style={{ fontSize: 17 }}>{alert.message}</h1>
          <p style={{ fontSize: 12 }}>
            {moment(alert.date).format("HH:mm:ss DD-MM-YYYY")}
          </p>
        </div>
      );
    }

    return alerts;
  }

  async onScroll() {
    console.log("Scroll");
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      await this.fetchNext();
    }
  }

  render() {
    return (
      <InfiniteScroll
        dataLength={this.state.alerts.length} //This is important field to render the next data
        next={async () => {
          await this.fetchNext();
        }}
        hasMore={true}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        // below props only if you need pull down functionality
        refreshFunction={async () => {
          this.setState({ refreshing: true });
          await this.reset();
          this.setState({ refreshing: false });
        }}
        pullDownToRefresh
        pullDownToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
        }
      >
        {this.getAlertsView()}
      </InfiniteScroll>
      // <div
      //   onScroll={async (event) => {
      //     await this.onScroll();
      //   }}
      //   // style={{ backgroundColor: theme.styles.backgroundColor }}
      //   // keyboardDismissMode="on-drag"
      //   // refreshControl={
      //   //   <div
      //   //     onRefresh={async () => {
      //   //       this.setState({ refreshing: true });
      //   //       await this.reset();
      //   //       this.setState({ refreshing: false });
      //   //     }}
      //   //     refreshing={!!this.state.refreshing}
      //   //   />
      //   // }
      //   // onScroll={async ({ nativeEvent }) => {
      //   //   if (
      //   //     nativeEvent.layoutMeasurement.height +
      //   //       nativeEvent.contentOffset.y >=
      //   //     nativeEvent.contentSize.height
      //   //   ) {
      //   //     await this.fetchNext();
      //   //   }
      //   // }}
      //   // scrollEventThrottle={300}
      // >
      //   {/* {__DEV__ && (
      //         <div
      //           onSearch={async (value) => {
      //             this.setState({ query: value });
      //             await this.reset();
      //           }}
      //         />
      //       )}
      //       <div
      //         loading={!!this.state.resetting}
      //         text={"Meldingen ophalen..."}
      //         textStyle={{ color: theme.styles.grayColor }}
      //         style={{ marginTop: 20, marginBottom: 20 }}
      //       /> */}
      //   {this.getAlertsView()}
      //   {/* <div
      //         loading={
      //           !!this.state.fetching &&
      //           this.state.lastPage?.currentPage !==
      //             this.state.lastPage?.amountOfPages
      //         }
      //         text={"Meldingen ophalen..."}
      //         textStyle={{ color: theme.styles.grayColor }}
      //         style={{ marginTop: 20, marginBottom: 20 }}
      //       />
      //       <View style={{ height: 50 }} /> */}
      // </div>
    );
  }

  async reset() {
    const activeFilters =
      (await StorageController.shared.getType<Filter[]>(
        SettingKey.ACTIVE_FILTERS
      )) || [];
    await setStateAsync(this, {
      lastPage: undefined,
      fetching: false,
      resetting: true,
      activeFilters: activeFilters,
    });
    const page = await this.fetchNext();
    this.setState({ alerts: page?.result || [], resetting: false });
  }
}
