import { API_BASE } from "../values";
import { UserController } from "./user.controller";
import { APIError } from "@bit/stefdegoey.azurefunctions-typeorm-base.request-resolver";
import { Page } from "../models/page.model";
import moment from "moment";

export class BaseEntityController<T extends { id?: string | number }> {
  protected date: Date = moment().toDate();

  constructor(protected urlPath: string) {}

  async create(entity: T) {
    const response = await fetch(`${API_BASE}/${this.urlPath}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          (await UserController.shared.getCredentials())?.accessToken
        }`,
      },
      body: JSON.stringify(entity),
    });

    if (!response.ok) {
      throw Array.from<APIError>(await response.json());
    }

    return (await response.json()) as T;
  }

  async delete(entity: T) {
    const response = await fetch(`${API_BASE}/${this.urlPath}/${entity.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${
          (await UserController.shared.getCredentials())?.accessToken
        }`,
      },
    });

    if (!response.ok) {
      throw Array.from<APIError>(await response.json());
    }

    return;
  }

  async getById(id: number): Promise<T> {
    const response = await fetch(`${API_BASE}/${this.urlPath}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          (await UserController.shared.getCredentials())?.accessToken
        }`,
      },
    });

    if (!response.ok) {
      throw Array.from<APIError>(await response.json());
    }

    return (await response.json()) as T;
  }

  async getPage(
    options: { page?: number; query?: string } = { page: 1 }
  ): Promise<Page<T>> {
    if (options.page === 1) {
      this.date = moment().toDate();
    }

    const response = await fetch(
      `${API_BASE}/${this.urlPath}?page=${
        options.page
      }&query=${encodeURIComponent(options.query || "")}&date=${moment(
        this.date
      ).toISOString()}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            (await UserController.shared.getCredentials())?.accessToken
          }`,
        },
      }
    );

    if (!response.ok) {
      throw Array.from<APIError>(await response.json());
    }

    return (await response.json()) as Page<T>;
  }

  async update(entity: T) {
    const response = await fetch(`${API_BASE}/${this.urlPath}/${entity.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${
          (await UserController.shared.getCredentials())?.accessToken
        }`,
      },
      body: JSON.stringify(entity),
    });

    if (!response.ok) {
      throw Array.from<APIError>(await response.json());
    }

    return (await response.json()) as T;
  }
}
