let _ANDROID_SCREENSHOT_PROCESS = false;
export const DEFAULT_MAP_REGION = {
  latitude: 52.13703199999998,
  latitudeDelta: 4.72390066888503,
  longitude: 5.240476000000029,
  longitudeDelta: 4.086913999999865,
};
// export const API_BASE = "http://10.0.0.47:7071/v1";
export const API_BASE = "https://api.p2000-alarm.nl/v1";
export let ANDROID_SCREENSHOT_PROCESS = (value?: boolean) => {
  if (value !== undefined) {
    _ANDROID_SCREENSHOT_PROCESS = value;
  }

  return _ANDROID_SCREENSHOT_PROCESS;
};
