import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import { AlertsView } from "./views/alerts/AlertsView";

export class App extends React.Component {
  render() {
    return (
      <Router>
        <div>
          {/* <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/alerts">Meldingen</Link>
              </li>
            </ul>
          </nav> */}

          <div className="container">
            <Switch>
              <Route path="/alerts" component={AlertsView} />
              <Redirect from="/" to="/alerts" />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
