export enum SettingKey {
  DEVICE_TOKEN = "DEVICE_TOKEN",
  USER = "USER",
  ACCESS_TOKEN = "ACCESS_TOKEN",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  ACCESS_TOKEN_ISSUED_AT = "ACCESS_TOKEN_ISSUED_AT",
  ACCESS_TOKEN_EXPIRES_AT = "ACCESS_TOKEN_EXPIRES_AT",
  DEVICE = "DEVICE",
  ACTIVE_FILTERS = "ACTIVE_FILTERS",
}

export class StorageController {
  static shared = new StorageController();
  private initialized = false;

  async _init(): Promise<void> {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
  }

  async debug(): Promise<void> {
    for (const key of await localStorage.getAllKeys()) {
      // tslint:disable-next-line:no-console
      console.log(key, localStorage.getItem(key));
    }
  }

  async getBoolean(key: SettingKey): Promise<boolean> {
    await this._init();
    return JSON.parse(localStorage.getItem(key) || "false");
  }

  async getNumber(key: SettingKey): Promise<number | null> {
    await this._init();
    return Number(localStorage.getItem(key));
  }

  async getString(key: SettingKey): Promise<string | null> {
    await this._init();
    return localStorage.getItem(key);
  }

  async getType<T>(key: SettingKey): Promise<T | null> {
    await this._init();
    const value = localStorage.getItem(key);

    if (!value) {
      return null;
    }

    return JSON.parse(value) as T;
  }

  async remove(...keys: SettingKey[]): Promise<void> {
    await localStorage.multiRemove(keys);
  }

  async reset(): Promise<void> {
    await localStorage.multiRemove(
      (await localStorage.getAllKeys()).filter(
        (e: string) => e !== SettingKey.DEVICE_TOKEN
      )
    );
    this.initialized = false;
  }

  async setBoolean(key: SettingKey, value: boolean): Promise<void> {
    await this._init();
    localStorage.setItem(key, Boolean(value).toString());
  }

  async setNumber(key: SettingKey, value: number): Promise<void> {
    await this._init();
    localStorage.setItem(key, Number(value).toString());
  }

  async setString(key: SettingKey, value: string): Promise<void> {
    await this._init();
    localStorage.setItem(key, value);
  }

  async setType<T>(key: SettingKey, value: T): Promise<void> {
    await this._init();
    localStorage.setItem(key, JSON.stringify(value));
  }
}
